import React, { useEffect, useState } from "react";
import { Container, LoginContainer } from "./AdminPage.style";
import { Button, FormLabel, FormRow, Input } from "../posts/Post.style";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../config/firebase";
import { FirebaseError } from "firebase/app";

interface AdminPageProps {
  stopLoading: () => void;
}

const AdminPage: React.FC<AdminPageProps> = ({ stopLoading }) => {
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleLoginClick = () => {
    signInWithEmailAndPassword(auth, login, password)
      .then((userCredentials) => {
        console.log(userCredentials);
      })
      .catch((error: FirebaseError) => {
        console.log(error);
        setLogin("");
        setPassword("");
      });
  };

  useEffect(() => {
    stopLoading();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <LoginContainer>
        <FormRow>
          <FormLabel>Login</FormLabel>
          <Input
            type="text"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
        </FormRow>

        <FormRow>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormRow>
        <Button onClick={handleLoginClick}>Enter</Button>
      </LoginContainer>
    </Container>
  );
};

export default AdminPage;
