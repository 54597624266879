import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

interface MainContainerProps {
  scaleRemove?: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
  height: 90vh;

  width: 80%;

  min-height: 500px;
  max-height: 700px;
  max-width: 300px;

  position: absolute;
  padding-top: 25px;
  z-index: 101;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  transform: ${(props) => props.scaleRemove && "none"};

  @media (min-width: 640px) {
    max-width: 500px;
  }
`;

export const QuoteContainer = styled.div`
  height: 100%;
  width: 80%;
  text-align: center;

  font-family: "Oswald";
  /* font-weight: bold; */
  font-weight: 500;
  font-size: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FadingInText = styled.span`
  animation: ${fadeIn} 3s linear;
  cursor: pointer;
`;

//secret login
export const SecretLoginContainer = styled.div`
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const slideDown = keyframes`
  100% {
    transform: translateY(calc(50vh - 50%));
  }
`;

export const RabbitHoleVideo = styled(ReactPlayer)`
  padding-top: 20%;
  width: 100%;
  max-width: 300px;
`;

interface RabbitHoleProps {
  showImage: boolean;
}

export const RabbitHole = styled.img<RabbitHoleProps>`
  padding-top: 20%;
  width: 100%;

  animation: ${(props) => (props.showImage ? slideDown : "")} 2s ease-in-out;
`;

export const SecretInputContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;
`;

export const SecretInput = styled.input`
  height: 20px;
`;

export const DoorIcon = styled.img`
  height: 20px;
  width: 20px;

  &:hover {
    transform: scale(1.2);
  }
`;

export const ErrorMessageContainer = styled.div`
  margin-top: 10px;
  font-family: "VT323";
  font-size: 18px;
  width: 50%;
  height: 5%;

  color: red;

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlowerContainer = styled.div`
  width: 30%;
  height: 20%;
  margin-bottom: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlowerIcon = styled.img`
  height: 75px;
  width: 50px;
`;

export const NavbarIconsContainer = styled.div`
  /* height: 20%; */

  width: 120%;

  display: flex;

  justify-content: center;
  align-items: flex-start;
`;

//SecretHome

const fontFamily = "Oswald";

export const LinksContainer = styled.div`
  padding-top: 30px;
  height: 80%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
`;

export const EscapeTrailerPlayerContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 300px;
`;

export const SecretText = styled.span`
  font-size: 35px;
  font-family: ${fontFamily};
  font-weight: 600;
  text-decoration: none;
  color: black;

  text-align: center;

  /* white-space: wrap; */

  cursor: pointer;

  transition: all 0.3 ease-in;
  &:hover {
    text-decoration: underline;
  }
`;

export const SecretLink = styled(Link)`
  font-size: 35px;
  font-family: ${fontFamily};
  font-weight: 600;
  text-decoration: none;
  color: black;

  text-align: center;

  white-space: nowrap;

  cursor: pointer;

  transition: all 0.3 ease-in;
  &:hover {
    text-decoration: underline;
  }
`;

//secret music page

export const TitleContainer = styled.div`
  font-family: ${fontFamily};
  font-size: 32px;
  height: 10%;

  min-width: 250px;
  min-height: 90px;
  /* background: red; */

  width: 90%;
  display: flex;
  align-items: flex-end;
`;

export const BackContainer = styled.div`
  width: 90%;
  height: 60px;

  display: flex;
  align-items: end;
`;

export const BackIcon = styled.img`
  width: 20px;
  height: 20px;

  &:hover {
    width: 25px;
    height: 25px;
  }
`;
