import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Links from "./features/links/Links";
import AdminPage from "./features/admin/AdminPage";
import { BackToUpArrowIcon, Center, TooLowWidth } from "./App.style";
import Navbar from "./features/navbar/Navbar";

import { auth, db } from "../config/firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import Home from "./features/home/Home";
import Music from "./features/music/Music";
import { IPost } from "./features/posts/Post";
import LoadingScreen from "./features/settings/LoadingScreen";
import { User, onAuthStateChanged } from "firebase/auth";
import Secret from "./features/secret/Secret";
import Screenshots from "./features/secret/content/Screenshots";
import ARTrailers from "./features/secret/content/ARTrailers";
import ARSeries from "./features/secret/content/ARSeries";
import arrowUp from "./assets/secret/arrowUp.svg";
import ScrollToTop from "./features/ScrollToTop";
import Chat from "./features/chat/Chat";

function App() {
  const [posts, setPosts] = useState<IPost[]>([]);

  const postsCollectionRef = collection(db, "posts");

  const getPosts = async () => {
    const postsQuery = query(postsCollectionRef, orderBy("createdAt", "desc"));
    const data = await getDocs(postsQuery);

    const result = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setPosts(result as any);
    // setLoading(false);
  };

  useEffect(() => {
    getPosts();

    //dodano bo sie psuło
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [loading, setLoading] = useState<boolean>(true);

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const reloadPage = () => {
    setTimeout(() => {
      getPosts();
    }, 200);
    setLoading(false);
  };

  //login state

  const [authUser, setAuthUser] = useState<User | null>(null);

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => {
      listen();
    };
  }, []);

  const [secretVisible, setSecretVisible] = useState<boolean>(false);

  useEffect(() => {
    const secret = localStorage.getItem("visitedSecretPage");
    if (secret) setSecretVisible(true);
  }, []);

  const handleSetSecretVisible = () => {
    setSecretVisible(true);
  };

  //arrow logic

  const [showArrow, setShowArrow] = useState<boolean>(false);

  useEffect(() => {
    const checkScroll = () => {
      setShowArrow(window.scrollY > 90);
    };

    // Initial check
    checkScroll();

    // Check when page is scrolled
    const watchScroll = setInterval(checkScroll, 100);

    return () => clearInterval(watchScroll);
  }, []);

  function scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "visible";
  }, [loading]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Center>
        <Navbar />

        <Routes>
          <Route
            path="/rabbit-hole/*"
            element={<Navigate replace to="/rabbit-hole" />}
          />
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route
            path="/"
            element={
              <Home
                posts={posts}
                reloadPage={reloadPage}
                stopLoading={stopLoading}
              />
            }
          />
          <Route path="/stream" element={<Links stopLoading={stopLoading} />} />
          <Route
            path="/music"
            element={
              <Music
                posts={posts}
                reloadPage={reloadPage}
                stopLoading={stopLoading}
              />
            }
          />
          <Route path="/chat" element={<Chat />} />
          <Route
            path="/rabbit-hole"
            element={
              <Secret
                handleSetSecretVisible={handleSetSecretVisible}
                stopLoading={stopLoading}
              />
            }
          />
          {!authUser && (
            <Route
              path="/admin"
              element={<AdminPage stopLoading={stopLoading} />}
            />
          )}
          {secretVisible && (
            <>
              <Route
                path="/rabbit-hole/choose"
                element={<Screenshots stopLoading={stopLoading} />}
              />
              <Route
                path="/rabbit-hole/ar-trailers"
                element={<ARTrailers stopLoading={stopLoading} />}
              />
              <Route
                path="/rabbit-hole/ar-series"
                element={<ARSeries stopLoading={stopLoading} />}
              />
            </>
          )}
        </Routes>
        {showArrow && (
          <BackToUpArrowIcon
            src={arrowUp}
            isVisible={showArrow}
            onClick={scrollToTop}
          />
        )}
      </Center>
      {loading && <LoadingScreen />}
      {/* )} */}
      <TooLowWidth>WTF MAN WHY?</TooLowWidth>
    </BrowserRouter>
  );
}

export default App;
