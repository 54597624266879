import React, { useEffect, useState } from "react";
import { MainContainer } from "./Secret.style";
import SecretLogin from "./SecretLogin";
import Quotes from "./Quotes";
import SecretHome from "./SecretHome";

interface SecretProps {
  handleSetSecretVisible: () => void;
  stopLoading: () => void;
}

const Secret: React.FC<SecretProps> = ({
  handleSetSecretVisible,
  stopLoading,
}) => {
  const [state, setState] = useState<"quotes" | "login" | "content">("quotes");

  const handleShowSecretLogin = () => {
    setState("login");
  };

  const handleShowSecretLinks = () => {
    setState("content");
    handleSetSecretVisible();
  };

  useEffect(() => {
    stopLoading();
    const secret = localStorage.getItem("visitedSecretPage");
    if (secret !== null) {
      setState("content");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContainer scaleRemove={state === "content"}>
      {state === "quotes" ? (
        <Quotes handleShowSecretLogin={handleShowSecretLogin} />
      ) : state === "login" ? (
        <SecretLogin handleShowSecretLinks={handleShowSecretLinks} />
      ) : (
        <SecretHome />
      )}
    </MainContainer>
  );
};

export default Secret;
