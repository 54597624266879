import React, { useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import {
  AudioPlayerContainer,
  Button,
  CustomLink,
  PlayerButtonsContainer,
} from "./Music.style";
import "./custom-audio-player.css";

interface MusicPlayerProps {
  title: string;
  songSrc: string;
  buttonsVisible: boolean;
}

const MusicPlayer: React.FC<MusicPlayerProps> = ({
  title,
  songSrc,
  buttonsVisible,
}) => {
  const name = require("../../assets/music/name.mp3");
  const escape = require("../../assets/music/escape.mp3");
  const doYouRemember = require("../../assets/secret/ar-trailers/do-your-remember.mp3");
  const migraine = require("../../assets/secret/ar-trailers/migraine.mp3");
  const trans = require("../../assets/secret/ar-trailers/trans.mp3");

  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setLinkCopied(true);
  };

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    }
  }, [linkCopied]);

  const returnSongBasedOnSrc = (src: string) => {
    switch (src) {
      case "name":
        return name;
      case "escape":
        return escape;
      case "doYouRemember":
        return doYouRemember;
      case "migraine":
        return migraine;
      case "trans":
        return trans;
    }
  };

  return (
    <AudioPlayerContainer>
      <AudioPlayer
        header={title}
        src={`${returnSongBasedOnSrc(songSrc)}`}
        onPlay={() => console.log("play!")}
        style={{ border: "1px solid black" }}
      />

      {buttonsVisible && (
        <PlayerButtonsContainer>
          <CustomLink to="https://linktr.ee/kayduffbloom" target="_blank">
            <Button>stream</Button>
          </CustomLink>

          <CustomLink to="https://kayduffbloom.bandcamp.com/" target="_blank">
            <Button>buy</Button>
          </CustomLink>

          <Button onClick={handleCopyLink}>
            {linkCopied ? "copied" : "share"}
          </Button>
        </PlayerButtonsContainer>
      )}
    </AudioPlayerContainer>
  );
};

export default MusicPlayer;
