import React, { useState } from "react";
import { IPost } from "./Post";
import { AddPostContainer, Button } from "./Post.style";
// import { collection } from "firebase/firestore";
// import { db } from "../../../config/firebase";
import PostModal from "./PostModal";
import { Timestamp } from "firebase/firestore";

interface AddPostProps {
  reloadPage: () => void;
}

const AddPost: React.FC<AddPostProps> = ({ reloadPage }) => {
  const [post, setPost] = useState<IPost>({
    id: "",
    type: "text",
    title: "",
    text: "",
    musicPage: false,
    createdAt: Timestamp.fromMillis(Date.now()),
  });

  const [isAddPostVisible, setIsAddPostVisible] = useState<boolean>(false);

  const handleAddPostClick = () => {
    setIsAddPostVisible(!isAddPostVisible);
  };

  let addModal;
  if (isAddPostVisible) {
    addModal = (
      <PostModal
        openModal={isAddPostVisible}
        post={post}
        setSelectedPost={setPost}
        onClose={() => setIsAddPostVisible(false)}
        type={"new"}
        reloadPage={reloadPage}
      />
    );
  }

  return (
    <AddPostContainer>
      <Button onClick={handleAddPostClick} color={"ghost"}>
        {isAddPostVisible ? "hide" : "add post"}
      </Button>

      {addModal}
    </AddPostContainer>
  );
};

export default AddPost;
