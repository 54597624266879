import { Link } from "react-router-dom";
import styled from "styled-components";

const fontFamily = "Oswald";

export const MainContainer = styled.div`
  padding-top: 30px;
  height: 100%;

  min-height: calc(100vh - 200px);

  width: 70%;
`;

export const SocialsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-evenly;
`;

export const MailContainer = styled.div`
  margin-top: 16px;
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-evenly;
`;

export const StreamLink = styled(Link)<{ $grey?: boolean }>`
  font-size: 35px;
  font-family: ${fontFamily};
  font-weight: 600;
  text-decoration: none;
  color: ${({ $grey }) => ($grey ? "grey" : "black")};

  cursor: pointer;

  transition: all 0.3 ease-in;

  &:hover {
    color: black;
    text-decoration: underline;
  }
`;

export const StreamText = styled.span`
  font-size: 35px;
  font-family: ${fontFamily};
  font-weight: 600;
  text-decoration: none;
  color: grey;

  cursor: pointer;

  transition: all 0.3 ease-in;
  &:hover {
    color: black;
    text-decoration: underline;
  }
`;

//Mail modal

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

interface ModalContentProps {
  src?: string;
}

export const ModalContent = styled.div<ModalContentProps>`
  position: relative;
  max-width: 400px;
  width: 90%;
  max-height: 90vh;
  background-color: #fff;

  @media (min-width: 640px) {
    max-height: 90vh;
  }

  border-radius: 14px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 10000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: black;
`;

export const MailHeader = styled.div`
  font-size: 32px;
  font-family: ${fontFamily};

  margin-top: 15px;
  /* margin-bottom: 10px; */
`;

export const MailText = styled.div`
  padding-top: 3%;
  height: 15%;
  width: 100%;

  font-size: 20px;
  font-family: "VT323";
`;

export const MailFormContainer = styled.form`
  display: flex;
  flex-direction: column;

  gap: 10px;
  width: 80%;
`;

export const Input = styled.input`
  height: 20px;

  font-size: 20px;
  border: 1px solid black;

  background: #d9d9d9;
  font-family: "VT323";
`;

export const Textarea = styled.textarea`
  font-size: 20px;
  background: #d9d9d9;
  font-family: "VT323";
  border: 1px solid black;

  min-height: 50px;
`;

interface ButtonProps {
  active: boolean;
}

export const Button = styled.button<ButtonProps>`
  margin: 20px 0px;

  font-size: 32px;

  border: none;
  font-family: ${fontFamily};
  background: white;
  color: black;

  cursor: pointer;

  &:hover {
    text-decoration: ${(props) => (props.active ? "underline" : "none")};
  }
`;

export const SubmitedForm = styled.div`
  height: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 32px;

  border: none;
  font-family: ${fontFamily};
`;
