import React, { useEffect } from "react";
import { IPost } from "../posts/Post";
import { MainContainer } from "./Music.style";
import PostList from "../posts/PostList";

interface MusicProps {
  posts: IPost[];
  reloadPage: () => void;
  stopLoading: () => void;
}

const Music: React.FC<MusicProps> = ({ posts, reloadPage, stopLoading }) => {
  useEffect(() => {
    stopLoading();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <MainContainer>
      <PostList
        posts={posts}
        musicPage={true}
        reloadPage={reloadPage}
        stopLoading={stopLoading}
      />
    </MainContainer>
  );
};

export default Music;
