import styled from "styled-components";
import { fontFamily } from "../../../App.style";

export const SendMessageModalContainer = styled.div`
  padding: 30px 0px;
  width: 80%;
  min-width: 320px;

  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SendModalP = styled.p`
  font-size: 30px;
  font-family: ${fontFamily};
  font-weight: 600;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: black;
  white-space: nowrap;
  margin: 0;
`;

export const StyledLink = styled.a`
  margin-top: 5px;
  font-size: 35px;
  font-family: ${fontFamily};
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: #0e89ba;

  margin: 0;
`;
