import React, { useEffect, useState } from "react";
import { QuoteContainer, FadingInText } from "./Secret.style";

interface QuotesProps {
  handleShowSecretLogin: () => void;
}

const Quotes: React.FC<QuotesProps> = ({ handleShowSecretLogin }) => {
  const [step, setStep] = useState<number>();

  const handleGoToNextStepClick = () => {
    if (step !== undefined)
      if (step < 3) {
        setStep(step + 1);
      } else {
        handleShowSecretLogin();
      }
  };

  const quotes = [
    "in the world of chaos, you are a found child of tomorrow",
    "wandering through chaos of digital sorrow",
    "come follow the morbid corruptions of schism",
    "walking in compulsions of Angry Retrofuturism",
  ];

  useEffect(() => {
    setTimeout(() => {
      setStep(0);
    }, 2000);
  }, []);

  return (
    <QuoteContainer onClick={handleGoToNextStepClick}>
      {step !== undefined && step === 0 && (
        <FadingInText key={step}>{quotes[step]}</FadingInText>
      )}
      {step !== undefined && step !== 0 && (
        <span style={{ cursor: "pointer" }} key={step}>
          {quotes[step]}
        </span>
      )}
    </QuoteContainer>
  );
};

export default Quotes;
