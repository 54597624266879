import React, { useState } from "react";
import {
  LinksContainer,
  MainContainer,
  NavbarIconsContainer,
  SecretLink,
} from "./Secret.style";
import NavbarIcons from "../navbar/NavbarIcons";
import SendMessageModal from "./sendMessageModal/SendMessageModal";

const SecretHome = () => {
  const [isSendMessageModalVisible, setIsSendMessageModalVisible] =
    useState<boolean>(false);

  let sendMessageModal;
  if (isSendMessageModalVisible) {
    sendMessageModal = (
      <SendMessageModal
        closeModal={() => setIsSendMessageModalVisible(false)}
      />
    );
  }

  return (
    <MainContainer>
      {sendMessageModal}

      <LinksContainer>
        <SecretLink to="/rabbit-hole/ar-series">AR series</SecretLink>
        <SecretLink to="/rabbit-hole/ar-trailers">
          Retrotransmissions
        </SecretLink>
        <SecretLink to="/rabbit-hole/choose">
          Deeper into the rabbit hole
        </SecretLink>
      </LinksContainer>

      <NavbarIconsContainer>
        <NavbarIcons />
      </NavbarIconsContainer>
    </MainContainer>
  );
};

export default SecretHome;
