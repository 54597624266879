import React from "react";
import "../../../index.css";
import {
  CloseButton,
  ModalForm,
  ModalTitleContainer,
  PostModalContainer,
} from "./Post.style";
import { IPost } from "./Post";
import PostForm from "./PostForm";

interface PostModalProps {
  openModal: boolean;
  post: IPost;
  setSelectedPost: React.Dispatch<React.SetStateAction<IPost>>;
  onClose: () => void;
  type: "edit" | "new";
  reloadPage: () => void;
}

const PostModal: React.FC<PostModalProps> = ({
  openModal,
  onClose,
  post,
  setSelectedPost,
  type,
  reloadPage,
}) => {
  if (!openModal) return null;
  return (
    <PostModalContainer>
      <CloseButton onClick={onClose}>
        <p>X</p>
      </CloseButton>
      <ModalForm>
        <ModalTitleContainer>
          {type === "edit" ? "edit" : "add post"}
        </ModalTitleContainer>

        <PostForm
          post={post}
          setPost={setSelectedPost}
          type={type}
          closeModal={onClose}
          reloadPage={reloadPage}
        />
      </ModalForm>
    </PostModalContainer>
  );
};

export default PostModal;
