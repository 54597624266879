import styled from "styled-components";

const fontFamily = "VT323";

export const PostContainer = styled.div`
  width: 100%;

  max-width: 400px;
`;

export const ImageContainer = styled.img`
  width: 100%;
  height: 100%;

  overflow: hidden;
`;

export const TitleContainer = styled.div`
  padding-top: 4%;

  font-size: 1.4rem;
  font-family: ${fontFamily};
`;

export const TextContainer = styled.div`
  padding-top: 3%;
  height: 15%;
  width: 100%;

  font-size: 1rem;
  font-family: ${fontFamily};

  overflow-wrap: break-word;
`;

export const PostScreen = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;
`;

export const BandcampFrame = styled.iframe`
  height: 300px;
  width: 300px;
  border: 0px;

  @media (min-width: 640px) {
    height: 400px;
    width: 400px;
  }
`;

export const AdminButtonsContainer = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-evenly;
  z-index: 1100;
`;

//Add post
export const AddPostContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
`;

export const AddPostForm = styled.form`
  width: 100%;
  /* margin: 20px; */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 250px;

  max-width: 300%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
`;

export const FormRow = styled.div`
  height: 20%;
  width: 70%;
  max-width: 300px;

  display: flex;
  flex-direction: column;
`;

export const FormLabel = styled.label`
  padding-left: 5px;
  font-family: ${fontFamily};
  font-size: 1.2rem;
  color: "black";
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
`;

export const Button = styled.button`
  font-family: ${fontFamily};
  min-width: 100px;
  font-size: 1.3rem;
  height: 30px;
  color: black;
  background: #e5e5e5;

  &:hover {
    text-decoration: underline;
  }
`;

//post modal

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`;
export const PostModalContainer = styled.div`
  max-width: 500px;
  min-width: 280px;

  width: 80%;
  height: 350px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  background-color: #ffffff;

  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  z-index: 100;
`;

export const ModalForm = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  right: 15px;

  z-index: 100;
  align-items: center;
  justify-content: space-around;
  font-family: ${fontFamily};
`;

export const ModalTitleContainer = styled.div`
  height: 10%;
  font-size: 40px;
  font-family: ${fontFamily};
  text-align: center;
`;

export const CloseButton = styled.div`
  position: relative;
  top: 20px;
  right: 20px;
  font-family: ${fontFamily};
  font-size: 30px;
  cursor: pointer;

  height: 40px;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  height: 20px;
`;

export const DeleteModalContainer = styled.div`
  max-width: 500px;
  min-width: 280px;

  width: 80%;
  height: 150px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  z-index: 100;
`;

//post preview modal

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

interface ModalContentProps {
  src?: string;
}

export const ModalContent = styled.div<ModalContentProps>`
  position: relative;
  max-width: 475px;
  width: 90%;
  max-height: 90vh;
  background-color: #fff;

  @media (min-width: 640px) {
    max-height: 90vh;
  }

  border-radius: 14px;
  /* padding: 40px 30px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 10000;

  display: flex;
  justify-content: center;
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: white;
`;

export const PhotoPreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 750px;
`;
