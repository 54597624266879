import styled from "styled-components";
import { fontFamily } from "../../App.style";

export const FirstModalContainer = styled.div`
  padding: 40px 0px;
  padding-bottom: 20px;
  width: 80%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const RabbitHoleImg = styled.img`
  width: 100%;
`;

export const P = styled.p`
  font-size: 20px;
  font-family: ${fontFamily};
  font-weight: 500;
  text-decoration: none;
  color: black;
  margin: 0;
`;

export const StyledLink = styled.a`
  margin-top: 5px;
  font-size: 20px;
  font-family: ${fontFamily};
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: #0e89ba;

  margin: 0;
`;
