import styled from "styled-components";

const fontFamily = "Oswald";

export const NavbarContainer = styled.div`
  width: 100%;
  height: 180px;
  position: relative;
  top: 0;
  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoutButtonContainer = styled.div`
  padding-top: 10px;
`;

export const TitleContainer = styled.div`
  width: 80%;
  height: 60%;
  font-family: ${fontFamily};
  font-weight: 600;
  font-size: 43px;
  display: flex;

  align-items: center;
  justify-content: center;

  min-width: 270px;
`;

export const IconsContainer = styled.div`
  width: 60%;
  height: 40%;

  min-width: 200px;
  max-width: 350px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

interface ExtendedIcon {
  selected: boolean;
}

export const Icon = styled.img<ExtendedIcon>`
  height: ${(props) => (props.selected ? "35px" : "30px")};
  width: ${(props) => (props.selected ? "35px" : "30px")};
  transition: all 0.3 ease;
  position: relative;

  &:hover {
    transform: scale(1.1);
  }
`;
