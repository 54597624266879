import React from "react";
import {
  ModalWrapper,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "../links/Links.styles";
import {
  FirstModalContainer,
  P,
  RabbitHoleImg,
  StyledLink,
} from "./FirstModal.style";
import rabbitHole from "../../assets/navbar/rabbithole.png";

interface FirstModalProps {
  closeModal: () => void;
}

const FirstModal: React.FC<FirstModalProps> = ({ closeModal }) => {
  return (
    <ModalWrapper>
      <ModalOverlay onClick={closeModal}></ModalOverlay>
      <ModalContent>
        <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
        <FirstModalContainer>
          <RabbitHoleImg src={rabbitHole} />
          <P>Do you want to enter the rabbit hole?</P>
          <P>Text +447587996107</P>
          <P>or</P>
          <StyledLink
            href="https://newsletter.kayduffbloom.com/"
            target="_blank"
          >
            sign up for the newsletter
          </StyledLink>

          <P style={{ marginTop: 10, fontSize: 16 }}>
            you'll see this pop-up only once
          </P>
        </FirstModalContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default FirstModal;
