import React, { useEffect, useState } from "react";
import { MainContainer } from "./Home.style";
import { IPost } from "../posts/Post";
import AddPost from "../posts/AddPost";
import PostList from "../posts/PostList";
import { auth } from "../../../config/firebase";
import FirstModal from "./FirstModal";

interface HomeProps {
  posts: IPost[];
  reloadPage: () => void;
  stopLoading: () => void;
}

const Home: React.FC<HomeProps> = ({ posts, reloadPage, stopLoading }) => {
  const [isFirstModalVisible, setIsFirstModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setIsFirstModalVisible(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  let firstModal;
  if (isFirstModalVisible) {
    firstModal = (
      <FirstModal closeModal={() => setIsFirstModalVisible(false)} />
    );
  }
  return (
    <MainContainer>
      {firstModal}
      {auth.currentUser && <AddPost reloadPage={reloadPage} />}

      <PostList
        posts={posts}
        musicPage={false}
        reloadPage={reloadPage}
        stopLoading={stopLoading}
      />
    </MainContainer>
  );
};

export default Home;
