import { Link } from "react-router-dom";
import styled from "styled-components";

const fontFamily = "VT323";

export const MainContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;

  min-height: calc(100vh - 230px);
  max-width: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 640px) {
    max-width: 500px;
  }
`;

//MUSIC PLAYER

export const ProgressBarContainer = styled.div`
  margin-top: 20px;

  width: 100%;
  height: 2px;
  background: black;
`;

interface ProgressProps {
  current: number;
}

export const Progress = styled.div<ProgressProps>`
  background: purple;
  position: relative;
  top: -3px;
  height: 10px;
  width: 10px;
  left: ${(props) => `${props.current}%`};
  border-radius: 50%;
`;

export const AudioPlayerContainer = styled.div`
  min-width: 220px;

  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const PlayerButtonsContainer = styled.div`
  width: 60%;

  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: black;
`;

export const CustomLink = styled(Link)`
  color: black;
`;

export const Button = styled.button`
  font-family: ${fontFamily};
  color: black;
  text-decoration: none;

  height: 25px;
  font-size: 16px;
  text-align: center;

  border: 1px solid gray;

  &:hover {
    text-decoration: underline;
  }
`;
