import React, { useState, useEffect, useRef } from "react";
import {
  ChatContainer,
  MessageList,
  MessageRow,
  MessageText,
  InputContainer,
  Input,
  SendButton,
  DateBox,
  DeleteButton,
  ChatTitle,
  ChatComponentContainer,
  InstructionsContainer,
  FakeLink,
} from "./Chat.style";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  Timestamp,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { auth, db } from "../../../config/firebase"; // Your Firebase config file

interface IChatMessage {
  id?: string;
  text: string;
  date: string;
  username: string;
}

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");

  const chatCollectionRef = collection(db, "chat");

  const messageListRef = useRef<HTMLDivElement | null>(null);

  // Fetch chat messages from Firestore in real-time
  useEffect(() => {
    const q = query(chatCollectionRef, orderBy("timestamp", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages: IChatMessage[] = [];
      snapshot.forEach((doc) => {
        fetchedMessages.push({
          id: doc.id,
          text: doc.data().text,
          date: doc.data().date,
          username: doc.data().username,
        });
      });
      setMessages(fetchedMessages);
    });
    return () => unsubscribe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const addMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const today = new Date();
    const formattedDate = `${String(today.getHours()).padStart(
      2,
      "0"
    )}:${String(today.getMinutes()).padStart(2, "0")}, ${String(
      today.getDate()
    ).padStart(2, "0")}.${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}.${today.getFullYear()}`;

    if (message.trim() && username.trim()) {
      await addDoc(chatCollectionRef, {
        username: username,
        text: message,
        date: formattedDate,
        timestamp: Timestamp.now(), // Firestore timestamp for ordering
      });

      setMessage("");
    }
  };

  const deleteMessage = async (messageId: string) => {
    const messageDocRef = doc(db, "chat", messageId);
    await deleteDoc(messageDocRef);
  };

  return (
    <ChatComponentContainer>
      <ChatContainer>
        <ChatTitle>The Social Outcast Club</ChatTitle>
        <MessageList ref={messageListRef}>
          {messages.map((msg) => (
            <MessageRow key={msg.id}>
              <MessageText>
                <b>{msg.username}</b>
              </MessageText>
              <MessageText>{msg.text}</MessageText>
              <DateBox>
                {msg.date}

                {auth.currentUser && (
                  <DeleteButton onClick={() => deleteMessage(msg.id!)}>
                    X
                  </DeleteButton>
                )}
              </DateBox>
            </MessageRow>
          ))}
        </MessageList>

        <InputContainer onSubmit={addMessage}>
          <Input
            type="text"
            maxLength={16}
            placeholder="Type your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <Input
            maxLength={256}
            type="text"
            placeholder="Type your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <SendButton type="submit">Send</SendButton>
        </InputContainer>
      </ChatContainer>
      <InstructionsContainer>
        <div style={{ fontSize: 30 }}>
          Welcome to The Social Outcast Club. A place to meet new friends.
        </div>
        <div>Write something to the void. Maybe it will answer.</div>
        <br />
        <div>Intention here is to find someone cool to talk to.</div>
        <div>
          If you feel like you have no one close to you, or you feel like
          meeting someone new.
        </div>
        <div>This place is for you.</div>

        <div style={{ marginTop: 32 }}>Notes:</div>
        <div>
          • No cookies, no registration, total anonymity. Disclose as much
          information as you desire. 
        </div>
        <div>
          • Please be civil. There’s no moderation and restrictions here, so
          this place depends on your respect and how you will behave. Really, if
          you speak vile stuff, you only reflect badly on yourself.
        </div>
        <div>
          • Chat here with an intention to meet a new friend, or a group of
          friends who you will later switch to other, more secure and private
          chats with. 
        </div>
        <div style={{ marginTop: 8 }}>
          • If you want to help out or report something, message
          <FakeLink href="mailto:contact@kayduffbloom.com">
            contact@kayduffbloom.com
          </FakeLink>
        </div>
      </InstructionsContainer>
    </ChatComponentContainer>
  );
};

export default Chat;
