import React, { CSSProperties, FC } from "react";
import styled from "styled-components";
import Slick, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import leftArrow from "../../../assets/secret/left-arrow.png";
import rightArrow from "../../../assets/secret/right-arrow.png";

type ImageSliderProps = {
  images: string[];
  initialIndex?: number;
  onClose: () => void;
};

interface ArrowImageProps {
  right?: boolean;
  left?: boolean;
}

const ArrowImage = styled.img<ArrowImageProps>`
  display: block;

  @media (min-width: 640px) {
    right: ${(props) => props.right && "-50px"};
    left: ${(props) => props.left && "-50px"};
    width: 50px;
    height: 50px;
  }
`;

const SampleNextArrow: FC<{
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}> = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowImage
      right
      src={rightArrow}
      className={className}
      style={{
        ...style,
        display: "block",
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow: FC<{
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}> = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowImage
      left
      src={leftArrow}
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const ImageSlider: React.FC<ImageSliderProps> = ({
  images,
  initialIndex = 0,
  onClose,
}) => {
  const settings: Settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    initialSlide: initialIndex,
    centerPadding: "0%",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerPadding: "0%",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "0%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "0%",
        },
      },
    ],
  };

  return (
    <Overlay onClick={onClose}>
      <SliderWrapper onClick={(e) => e.stopPropagation()}>
        <Slick {...settings}>
          {images.map((image, index) => (
            <div key={index} style={{ height: "100%" }}>
              <ImageWrapper>
                <img
                  src={image}
                  alt={`Slide ${index}`}
                  style={{ width: "100%", height: "100%" }}
                />
              </ImageWrapper>
            </div>
          ))}
        </Slick>
      </SliderWrapper>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SliderWrapper = styled.div`
  width: 70%;
  max-width: 1200px;
  max-height: 80%;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 80%;
  }
`;

const ImageWrapper = styled.div`
  img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
`;

export default ImageSlider;
