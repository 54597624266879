import React, { useEffect } from "react";
import {
  AddPostForm,
  Input,
  FormRow,
  FormContainer,
  FormLabel,
  Button,
  ButtonContainer,
} from "./Post.style";
import { IPost, PostTypesEnum } from "./Post";
import {
  collection,
  doc,
  updateDoc,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../../config/firebase";

interface PostFormProps {
  post: IPost;
  setPost: React.Dispatch<React.SetStateAction<IPost>>;
  type: "edit" | "new";
  closeModal: () => void;
  reloadPage: () => void;
}

const PostForm: React.FC<PostFormProps> = ({
  post,
  setPost,
  type,
  closeModal,
  reloadPage,
}) => {
  const postsCollectionRef = collection(db, "posts");

  const updatePost = async (postId: string, title: any) => {
    const userDoc = doc(db, "posts", postId);
    await updateDoc(userDoc, post as any);

    reloadPage();
    closeModal();
  };

  const createPost = async () => {
    await addDoc(postsCollectionRef, post);

    reloadPage();
    closeModal();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (type === "edit") {
      updatePost(post.id, "title");
    } else {
      createPost();
    }

    setPost({
      id: "",
      text: "",
      title: "",
      link: "",
      musicPage: false,
      type: "text",
      createdAt: Timestamp.fromMillis(Date.now()),
    });

    return false;
  };

  const selectOptions = [
    { value: "text", text: "text" },
    { value: "image", text: "image" },
    { value: "yt", text: "youtube" },
    { value: "soundcloud", text: "soundcloud" },
    { value: "bandcamp", text: "bandcamp" },
    { value: "music-player", text: "music-player" },
  ];

  const musicSelectOpions = [
    { value: "escape", text: "Escape" },
    { value: "name", text: "Name" },
  ];

  useEffect(() => {
    if (post.type === "music-player" && post.link === undefined) {
      setPost({ ...post, link: "escape" });
    }
  }, [post]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (post.type === "music-player") {
      setPost({ ...post, musicPage: true });
    }
  }, [post.type]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AddPostForm id="add-post" onSubmit={handleSubmit}>
      <FormContainer>
        <FormRow>
          <FormLabel>Post type</FormLabel>
          <select
            style={{ color: "black" }}
            value={post.type}
            onChange={(e) =>
              setPost({ ...post, type: e.target.value as PostTypesEnum })
            }
          >
            {selectOptions.map((option, i) => {
              return (
                <option key={`select-value-${i}`} value={option.value}>
                  {option.text}
                </option>
              );
            })}
          </select>
        </FormRow>

        {post.type !== "music-player" && post.type !== "text" && (
          <FormRow>
            <FormLabel>Link</FormLabel>
            <Input
              placeholder="write here..."
              value={post.link}
              onChange={(e) => setPost({ ...post, link: e.target.value })}
            />
          </FormRow>
        )}

        {post.type === "music-player" && (
          <FormRow>
            <FormLabel>Song</FormLabel>
            <select
              value={post.link}
              onChange={(e) => setPost({ ...post, link: e.target.value })}
            >
              {musicSelectOpions.map((option, i) => {
                return (
                  <option key={`select-value-${i}`} value={option.value}>
                    {option.text}
                  </option>
                );
              })}
            </select>
          </FormRow>
        )}

        <FormRow>
          <FormLabel>Title</FormLabel>
          <Input
            placeholder="write here..."
            value={post.title}
            onChange={(e) => setPost({ ...post, title: e.target.value })}
          />
        </FormRow>

        <FormRow>
          <FormLabel>Text</FormLabel>
          <Input
            placeholder="write here..."
            value={post.text}
            onChange={(e) => setPost({ ...post, text: e.target.value })}
          />
        </FormRow>

        <FormRow>
          <FormLabel>Music page</FormLabel>
          <Input
            type="checkbox"
            checked={post.musicPage}
            onChange={(e) => setPost({ ...post, musicPage: e.target.checked })}
          />
        </FormRow>

        <ButtonContainer>
          <Button type="submit" form="add-post">
            {type === "edit" ? "edit" : "add"}
          </Button>
        </ButtonContainer>
      </FormContainer>
    </AddPostForm>
  );
};

export default PostForm;
