import { deleteDoc, doc } from "firebase/firestore";
import React, { useState } from "react";
import Post, { IPost } from "./Post";
import { db } from "../../../config/firebase";
import PostModal from "./PostModal";
import DeleteModal from "./DeleteModal";

interface PostListProps {
  posts: IPost[];
  musicPage: boolean;
  reloadPage: () => void;
  stopLoading: () => void;
}

const PostList: React.FC<PostListProps> = ({
  posts,
  musicPage,
  reloadPage,
  stopLoading,
}) => {
  const [selectedPost, setSelectedPost] = useState<IPost>(posts[0]);

  const handleEditClick = (post: IPost) => {
    setSelectedPost(post);
    setIsEditModalVisible(true);
  };

  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  let editModal;
  if (isEditModalVisible) {
    editModal = (
      <PostModal
        openModal={isEditModalVisible}
        post={selectedPost}
        setSelectedPost={setSelectedPost}
        onClose={() => setIsEditModalVisible(false)}
        type={"edit"}
        reloadPage={reloadPage}
      />
    );
  }

  const handleDeleteClick = (post: IPost) => {
    setSelectedPost(post);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteAfterModal = async () => {
    const userDoc = doc(db, "posts", selectedPost.id);
    await deleteDoc(userDoc);

    setIsDeleteModalVisible(false);
    reloadPage();
  };

  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  let deleteModal;
  if (isDeleteModalVisible && selectedPost) {
    deleteModal = (
      <DeleteModal
        text={"Are you sure you want to do this?"}
        title="Delete post"
        onClose={() => setIsDeleteModalVisible(false)}
        handleDeleteAfterModal={handleDeleteAfterModal}
      />
    );
  }

  const filteredMusicList = posts.filter((post) => post.musicPage);
  const filteredPosts = posts.filter((post) => post.type !== "music-player");

  return (
    <>
      {editModal}
      {deleteModal}

      {musicPage
        ? filteredMusicList.map((post, index) => (
            <Post
              key={`music-post-${index}`}
              postData={post}
              lastPost={index === filteredMusicList.length - 1}
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              reloadPage={reloadPage}
              stopLoading={index === 0 ? stopLoading : undefined}
            />
          ))
        : filteredPosts.map((post, index) => (
            <Post
              key={index}
              postData={post}
              lastPost={index === filteredPosts.length - 1}
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              reloadPage={reloadPage}
              stopLoading={index === 0 ? stopLoading : undefined}
            />
          ))}
    </>
  );
};

export default PostList;
