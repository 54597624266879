import React, { useEffect, useState, useRef } from "react";
import { ImageContainer } from "./Post.style";

type Props = {
  src: string;
  alt?: string;
  stopLoading?: () => void;
};

const LazyImage: React.FC<Props> = ({ src, alt, stopLoading }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    // Capture current value in the effect closure
    const current = imgRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setLoaded(true);
          if (current) {
            observer.unobserve(current);
          }
        }
      });
    });

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);

  return (
    <ImageContainer
      onLoad={stopLoading}
      ref={imgRef}
      data-src={src}
      alt={alt}
      src={loaded ? src : undefined} // only set the src if the image should be loaded
      style={loaded ? {} : { visibility: "hidden" }}
    />
  );
};

export default LazyImage;
