import React, { useEffect, useState } from "react";
import {
  DoorIcon,
  ErrorMessageContainer,
  FlowerContainer,
  FlowerIcon,
  NavbarIconsContainer,
  // RabbitHole,
  RabbitHoleVideo,
  SecretInput,
  SecretInputContainer,
  SecretLoginContainer,
} from "./Secret.style";
// import rabbitHole from "../../assets/navbar/rabbithole.png";
import doorIcon from "../../assets/navbar/Door.png";
import flower from "../../assets/navbar/V SIGN BODONI.png";
import NavbarIcons from "../navbar/NavbarIcons";
import loginVideo from "../../assets/secret/login-video.mp4";
import { useNavigate } from "react-router-dom";

interface SecretLoginProps {
  handleShowSecretLinks: () => void;
}

const SecretLogin: React.FC<SecretLoginProps> = ({ handleShowSecretLinks }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");

  const [videoStarted, setVideoStarted] = useState<boolean>(false);

  const [playing, setIsPlaing] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(true);

  const [enterTry, setEnterTry] = useState<number>(0);

  const handleDoorClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (entryCodes.includes(password)) {
      setIsPlaing(!playing);
      setMuted(!muted);
      setVideoStarted(true);

      localStorage.setItem("visitedSecretPage", "y");
    } else {
      if (enterTry < 8) {
        setEnterTry((prevTry) => (prevTry + 1) as number);
      } else {
        setEnterTry(9);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      setPassword("");
    }

    return false;
  };

  const entryCodes = [
    "pleaseletmein",
    "45632",
    "82106",
    "42609",
    "12151",
    "21632",
    "59694",
    "87582",
    "21839",
    "56946",
    "76457",
    "52831",
    "49898",
    "83849",
    "44825",
    "63043",
    "60607",
    "04908",
    "47726",
    "73874",
    "90208",
    "88755",
    "87379",
    "10524",
    "75512",
    "16317",
    "12990",
    "27761",
    "35263",
    "39208",
    "64517",
    "36053",
    "79019",
    "19484",
    "60365",
    "12884",
    "20040",
    "49050",
    "29678",
    "55021",
    "54679",
    "45657",
    "75592",
    "09470",
    "72001",
    "29364",
    "67761",
    "76510",
    "15414",
    "66494",
    "17331",
    "62838",
    "42389",
    "99930",
    "03286",
    "21993",
    "59919",
    "98218",
    "13147",
    "63937",
    "8072",
    "92547",
    "69850",
    "22694",
    "24116",
    "18627",
    "35609",
    "81012",
    "48612",
    "92659",
    "40363",
    "21604",
  ];

  //error messages
  const errorMessages = [
    `That’s a wrong code.`,
    `Sorry, that’s incorrect.`,
    "No, that is not quite correct.",
    "It appears you are wrong again.",
    "You will not get through, the code is wrong.",
    `This key won’t open this door.`,
    "You will not pry it open.",
    "Maybe you should leave.",
    "Goodbye.",
  ];

  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);

  return (
    <SecretLoginContainer>
      <RabbitHoleVideo
        url={loginVideo}
        playing={playing}
        muted={muted}
        onEnded={handleShowSecretLinks}
      />

      {!videoStarted && (
        <>
          <SecretInputContainer onSubmit={(e) => handleDoorClick(e)}>
            <SecretInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            <button
              type="submit"
              style={{ background: "transparent", border: "none" }}
            >
              <DoorIcon style={{ cursor: "pointer" }} src={doorIcon} />
            </button>
          </SecretInputContainer>

          {enterTry > 0 && (
            <ErrorMessageContainer>
              {errorMessages[enterTry - 1]}
            </ErrorMessageContainer>
          )}

          <FlowerContainer>
            <FlowerIcon src={flower} />
          </FlowerContainer>

          <NavbarIconsContainer>
            <NavbarIcons />
          </NavbarIconsContainer>
        </>
      )}
    </SecretLoginContainer>
  );
};

export default SecretLogin;
