import React, { useEffect } from "react";
import MusicPlayer from "../../music/MusicPlayer";
import {
  ARTrailersContainer,
  BackContainer,
  BackIcon,
} from "./SecretContent.style";
import { useNavigate } from "react-router-dom";
import backIcon from "../../../assets/secret/back-icon.png";

interface ARTrailersProps {
  stopLoading: () => void;
}

const ARTrailers: React.FC<ARTrailersProps> = ({ stopLoading }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/rabbit-hole");
  };

  useEffect(() => {
    stopLoading();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ARTrailersContainer>
      <MusicPlayer
        songSrc={"doYouRemember"}
        title={"Do you remember being born? (Angry Retrofuturism trailer 1)"}
        buttonsVisible={false}
      />
      <MusicPlayer
        songSrc={"trans"}
        title={"Transmissive Missiles (Angry Retrofuturism trailer 2)"}
        buttonsVisible={false}
      />
      <MusicPlayer
        songSrc={"migraine"}
        title={"Migraine Sensory Chamber (Angry Retrofuturism trailer 3)"}
        buttonsVisible={false}
      />
      <BackContainer>
        <BackIcon src={backIcon} onClick={goBack} />
      </BackContainer>
    </ARTrailersContainer>
  );
};

export default ARTrailers;
