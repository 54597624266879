import styled from "styled-components";

export const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;

  width: 70%;
  min-height: calc(100vh - 230px);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginContainer = styled.div`
  width: 60%;
  height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
