import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  MailFormContainer,
  MailHeader,
  MailText,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalWrapper,
  SubmitedForm,
  Textarea,
} from "./Links.styles";
import { useForm, ValidationError } from "@formspree/react";

interface MailModalProps {
  closeModal: () => void;
}

const MailModal: React.FC<MailModalProps> = ({ closeModal }) => {
  const [state, handleSubmit] = useForm("xayzgdbg");
  const [submit, setSubmit] = useState<boolean>(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (state.succeeded) {
      setSubmit(true);
    }
  }, [state, setSubmit]);

  const isValidMessage = () => {
    return message.length >= 10;
  };

  useEffect(() => {
    if (submit) {
      setTimeout(() => {
        closeModal();
      }, 1000);
    }
  }, [submit, closeModal]);

  return (
    <ModalWrapper>
      <ModalOverlay onClick={closeModal}></ModalOverlay>
      <ModalContent>
        {!submit && (
          <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
        )}
        {submit ? (
          <SubmitedForm>Your e-mail was sent, thanks.</SubmitedForm>
        ) : (
          <MailFormContainer onSubmit={handleSubmit}>
            <MailHeader>mail</MailHeader>
            <MailText>Your Mail</MailText>
            <Input id="email" type="email" name="email" />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            <MailText>Your Message</MailText>
            <Textarea
              id="message"
              name="message"
              onChange={(e) => setMessage(e.target.value)}
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />

            <Button
              active={isValidMessage()}
              type="submit"
              disabled={state.succeeded || !isValidMessage()}
            >
              {!isValidMessage() ? "write something" : "send"}
            </Button>
          </MailFormContainer>
        )}
      </ModalContent>
    </ModalWrapper>
  );
};

export default MailModal;
