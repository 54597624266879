import styled, { keyframes } from "styled-components";

export const fontFamily = "VT323";

export const Center = styled.div`
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  padding-bottom: 50px;

  @media (max-width: 270px) {
    display: none;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

interface ArrowProps {
  isVisible: boolean;
}

export const BackToUpArrowIcon = styled.img<ArrowProps>`
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  z-index: 1000;

  border: 3px solid black;

  background: white;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.8s forwards;

  &:active {
    transform: scale(1.08);
  }

  @media (min-width: 640px) {
    right: 60px;
    bottom: 60px;

    width: 60px;
    height: 60px;
  }
`;

export const TooLowWidth = styled.div`
  display: none;

  @media (max-width: 270px) {
    height: 100vh;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Oswald";
    font-size: 25px;
    min-width: 150px;
  }
`;

export const LoadingScreenContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  background: white;
  top: 0;
  z-index: 20000;
  overflow-y: hidden;
`;

export const LoadingAnimationContainer = styled.div`
  width: 50%;
  max-width: 400px;
  height: 50%;
`;
