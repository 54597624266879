import React from "react";
import { ModalCloseButton } from "../../links/Links.styles";
import {
  ModalWrapper,
  ModalOverlay,
  ModalContent,
} from "../content/SecretContent.style";
import {
  SendMessageModalContainer,
  SendModalP,
  StyledLink,
} from "./SMModal.style";

interface SendMessageModalProps {
  closeModal: () => void;
}

const SendMessageModal: React.FC<SendMessageModalProps> = ({ closeModal }) => {
  return (
    <ModalWrapper>
      <ModalOverlay onClick={closeModal}></ModalOverlay>
      <ModalContent>
        <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
        <SendMessageModalContainer>
          <SendModalP>Would you like to tell me</SendModalP>
          <SendModalP>please how're you</SendModalP>
          <SendModalP>feeling today?</SendModalP>

          <SendModalP style={{ marginTop: 30 }}>Text +447471648984</SendModalP>
          <SendModalP>or</SendModalP>
          <StyledLink href="mailto:contact@kayduffbloom.com">
            send an e-mail
          </StyledLink>
        </SendMessageModalContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default SendMessageModal;
