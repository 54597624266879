import React, { useState } from "react";
import {
  PostContainer,
  PostScreen,
  TextContainer,
  TitleContainer,
  BandcampFrame,
  AdminButtonsContainer,
  Button,
} from "./Post.style";
import ReactPlayer from "react-player";
import { Spacer } from "../home/Home.style";
import { auth } from "../../../config/firebase";
import MusicPlayer from "../music/MusicPlayer";
import { Timestamp } from "firebase/firestore";
import PostPreviewModal from "./PostPreviewModal";
import LazyImage from "./LazyImage";

export type PostTypesEnum =
  | "text"
  | "image"
  | "yt"
  | "soundcloud"
  | "bandcamp"
  | "music-player";

export interface IPost {
  id: string;
  type: PostTypesEnum;
  link?: string;
  title: string;
  text: string;
  musicPage: boolean;
  createdAt: Timestamp;
}

export interface PostProps {
  postData: IPost;
  lastPost: boolean;
  handleEditClick: (post: IPost) => void;
  handleDeleteClick: (post: IPost) => void;
  reloadPage: () => void;
  stopLoading?: () => void;
}

const Post: React.FC<PostProps> = ({
  postData,
  lastPost,
  handleEditClick,
  handleDeleteClick,
  stopLoading,
}) => {
  const renderContent = (type: string, link: string, title: string) => {
    switch (type) {
      case "image":
        return <LazyImage src={link} alt="content" stopLoading={stopLoading} />;
      case "soundcloud":
      case "yt":
        return (
          <ReactPlayer
            url={link}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        );
      case "music-player":
        return (
          <MusicPlayer songSrc={link} title={title} buttonsVisible={true} />
        );
      case "bandcamp":
        return (
          <BandcampFrame
            src={link}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        );
      default:
        return null;
    }
  };

  const [postPreviewVisible, setPostPreviewVisible] = useState<boolean>(false);
  const openPreviewModal = () => setPostPreviewVisible(true);
  const closePreviewModal = () => setPostPreviewVisible(false);

  let postPreviewModal;
  if (postPreviewVisible && postData.type === "image" && postData.link) {
    postPreviewModal = (
      <PostPreviewModal closeModal={closePreviewModal} src={postData.link} />
    );
  }

  const parseTextToHyperlink = (text: string) => {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-Z0-9+&@#/%=~_|])/gi;
    return text.split(" ").map((word, i) => {
      if (word.match(urlRegex)) {
        // Remove 'https://' and trailing '/' for display
        const cleanURL = word.replace(/(^\w+:|^)\/\//, "").replace(/\/$/, "");
        return (
          <a key={i} href={word} target="_blank" rel="noopener noreferrer">
            {cleanURL}
          </a>
        );
      } else {
        return word + " ";
      }
    });
  };

  return (
    <>
      {postPreviewModal}
      <PostContainer>
        {/* only visible for admin */}
        {auth.currentUser && (
          <AdminButtonsContainer>
            <Button onClick={() => handleEditClick(postData)}>edit</Button>

            <Button onClick={() => handleDeleteClick(postData)}>delete</Button>
          </AdminButtonsContainer>
        )}

        {postData.type !== "text" && postData.link && (
          <PostScreen onClick={openPreviewModal}>
            {renderContent(postData.type, postData.link, postData.title)}
          </PostScreen>
        )}

        {postData.type !== "music-player" && postData.text !== "" && (
          <TitleContainer>{postData.title}</TitleContainer>
        )}

        {postData.text !== "" && (
          <TextContainer>{parseTextToHyperlink(postData.text)}</TextContainer>
        )}
      </PostContainer>

      {!lastPost && <Spacer />}
    </>
  );
};

export default Post;
