// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCa3QWaw9uHoUNBZN7171nkiylUZSq9GB8",
  authDomain: "kayduff-bloom.firebaseapp.com",
  projectId: "kayduff-bloom",
  storageBucket: "kayduff-bloom.appspot.com",
  messagingSenderId: "919602416088",
  appId: "1:919602416088:web:08d97fd4b4fbc3ea780aad",
  measurementId: "G-8MSFFVL4JM",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);
