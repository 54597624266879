import React from "react";
import {
  CloseButton,
  ModalForm,
  ModalTitleContainer,
  DeleteModalContainer,
  Button,
} from "./Post.style";

interface DeleteModalProps {
  title: string;
  text: string;
  onClose: () => void;
  handleDeleteAfterModal: () => Promise<void>;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  title,
  text,
  onClose,
  handleDeleteAfterModal,
}) => {
  return (
    <DeleteModalContainer>
      <CloseButton onClick={onClose}>X</CloseButton>
      <ModalForm>
        <ModalTitleContainer>{title}</ModalTitleContainer>

        {text}

        <Button onClick={handleDeleteAfterModal}>Delete</Button>
      </ModalForm>
    </DeleteModalContainer>
  );
};

export default DeleteModal;
