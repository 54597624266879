import ReactPlayer from "react-player";
import styled from "styled-components";

const fontFamily = "VT323";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.div`
  position: relative;
  width: 80%;
  background-color: #fff;
  min-width: 300px;

  max-width: 570px;

  border-radius: 14px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 10000;

  display: flex;
  justify-content: center;
`;

export const CloseTrailerButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-family: ${fontFamily};
  font-size: 30px;
  cursor: pointer;

  height: 40px;
  width: 40px;

  z-index: 1001;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayTrailerButtonContainer = styled.div`
  position: absolute;
  width: 80%;
  height: 90%;
  z-index: 10001;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const PlayIcon = styled.img`
  width: 35%;
`;

export const EscapeTrailerPlayer = styled(ReactPlayer)`
  width: 100%;
  height: 100%;
  max-height: 500px;
`;

//ARTrailers
export const ARTrailersContainer = styled.div`
  padding-top: 50px;
  height: 90vh;
  width: 80%;

  min-height: 500px;
  max-height: 700px;
  max-width: 300px;

  position: absolute;
  top: 25px;
  z-index: 101;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 50px;

  @media (min-width: 640px) {
    max-width: 350px;
  }
`;

interface BackContainerProps {
  photos?: boolean;
}

export const BackContainer = styled.div<BackContainerProps>`
  margin-top: 5px;
  width: 100%;
  height: 60px;

  display: flex;
  align-items: flex-start;

  padding-left: ${(props) => props.photos && "20px"};

  @media (min-width: 640px) {
    padding-left: ${(props) => props.photos && "50px"};
  }
`;

export const BackIcon = styled.img`
  width: 25px;
  height: 25px;
  transform: scaleX(-1);

  &:hover {
    width: 30px;
    height: 30px;
  }

  @media (min-width: 640px) {
    height: 30px;
    width: 30px;

    &:hover {
      width: 35px;
      height: 35px;
    }
  }
`;

//screenshots

export const ScreenshotsContainer = styled.div`
  padding-top: 20px;
  height: 90vh;
  width: 80%;

  max-height: 700px;
  max-width: 300px;

  position: absolute;
  top: 25px;
  z-index: 101;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 640px) {
    max-width: 500px;
  }
`;

export const ContentContainer = styled.div`
  height: 80%;
  width: 100%;

  max-height: 600px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: auto;
  margin-bottom: 10px;

  @media (min-width: 640px) {
    height: 90%;
    margin-bottom: 20px;
  }
`;

export const DeeperContentContainer = styled.div`
  height: 80%;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: auto;
  margin-bottom: 10px;
  gap: 16px;

  @media (min-width: 640px) {
    height: 90%;
    margin-bottom: 20px;
  }
`;

export const SecretPhoto = styled.img`
  height: 30%;
  max-height: 150px;
  max-width: 150px;
  width: calc(50% - 4%);
  object-fit: cover;
  margin-bottom: 5%;

  @media (min-width: 640px) {
    max-height: 200px;
    max-width: 200px;
  }

  &:hover {
    transform: scale(1.05);
  }
`;
