import React from "react";
import {
  ModalWrapper,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  PreviewImage,
} from "./Post.style";

interface PostPreviewModalProps {
  closeModal: () => void;
  src: string;
}

const PostPreviewModal: React.FC<PostPreviewModalProps> = ({
  closeModal,
  src,
}) => {
  return (
    <ModalWrapper>
      <ModalOverlay onClick={closeModal}></ModalOverlay>
      <ModalContent src={src} onClick={closeModal}>
        <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
        {src && <PreviewImage src={src} />}
      </ModalContent>
    </ModalWrapper>
  );
};

export default PostPreviewModal;
