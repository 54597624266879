import React from "react";
import {
  LogoutButtonContainer,
  NavbarContainer,
  TitleContainer,
} from "./Navbar.style";

import { Button } from "../posts/Post.style";
import { signOut } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { auth } from "../../../config/firebase";
import NavbarIcons from "./NavbarIcons";

const Navbar = () => {
  const handleSignOutClick = () => {
    signOut(auth)
      .then(() => {
        console.log("signed out");
      })
      .catch((error: FirebaseError) => {
        console.log(error);
      });
  };

  return (
    <NavbarContainer>
      {auth.currentUser && (
        <LogoutButtonContainer>
          <Button onClick={handleSignOutClick}>Logout</Button>
        </LogoutButtonContainer>
      )}

      <TitleContainer>Kayduff Bloom</TitleContainer>

      <NavbarIcons />
    </NavbarContainer>
  );
};

export default Navbar;
