import React, { useState } from "react";
import {
  BackContainer,
  BackIcon,
  ContentContainer,
  ScreenshotsContainer,
  SecretPhoto,
} from "./SecretContent.style";
import screenshot1 from "../../../assets/secret/ar-series/1.jpg";
import screenshot2 from "../../../assets/secret/ar-series/2.jpeg";
import screenshot3 from "../../../assets/secret/ar-series/3.jpeg";
import screenshot4 from "../../../assets/secret/ar-series/4.jpeg";
import screenshot5 from "../../../assets/secret/ar-series/5.jpeg";
import screenshot6 from "../../../assets/secret/ar-series/6.jpeg";
import screenshot7 from "../../../assets/secret/ar-series/7.jpg";
import screenshot8 from "../../../assets/secret/ar-series/8.jpeg";
import screenshot9 from "../../../assets/secret/ar-series/9.jpeg";
import screenshot10 from "../../../assets/secret/ar-series/10.jpeg";
import screenshot11 from "../../../assets/secret/ar-series/11.jpeg";
import { useNavigate } from "react-router-dom";
import ImageSlider from "./ImageSlider";
import backIcon from "../../../assets/secret/back-icon.png";

interface ARSeriesProps {
  stopLoading: () => void;
}

const ARSeries: React.FC<ARSeriesProps> = ({ stopLoading }) => {
  const photos = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
    screenshot10,
    screenshot11,
  ];

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/rabbit-hole");
  };

  const [showSlider, setShowSlider] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = (index: number) => {
    setSelectedIndex(index);
    setShowSlider(true);
  };

  const handleCloseSlider = () => {
    setShowSlider(false);
  };

  return (
    <ScreenshotsContainer>
      <ContentContainer>
        {photos.map((photo, index) => (
          <SecretPhoto
            onLoad={stopLoading}
            key={`ar-series-${index}`}
            src={photo}
            onClick={() => handleClick(index)}
            alt="Gallery"
          />
        ))}
      </ContentContainer>
      <BackContainer photos={true}>
        <BackIcon src={backIcon} onClick={goBack} />
      </BackContainer>

      {showSlider && (
        <ImageSlider
          images={photos}
          initialIndex={selectedIndex}
          onClose={handleCloseSlider}
        />
      )}
    </ScreenshotsContainer>
  );
};

export default ARSeries;
