import styled from "styled-components";

export const MainContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;

  width: 80%;
  max-width: 300px;
  min-height: calc(100vh - 230px);

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 640px) {
    max-width: 500px;
  }
`;

export const Spacer = styled.div`
  margin-top: 30px;
  margin-bottom: 34px;
  height: 1px;
  width: 40%;
  max-width: 150px;
  background: black;
`;
