import React, { useState } from "react";
import {
  BackContainer,
  BackIcon,
  DeeperContentContainer,
  ScreenshotsContainer,
} from "./SecretContent.style";
import { useNavigate } from "react-router-dom";
import backIcon from "../../../assets/secret/back-icon.png";
import { SecretText } from "../Secret.style";
import secretImg from "../../../assets/Secret.jpg";

interface ScreenshotsProps {
  stopLoading: () => void;
}

const Screenshots: React.FC<ScreenshotsProps> = ({ stopLoading }) => {
  const navigate = useNavigate();

  const handleDownload = () => {
    // Create a link element
    const link = document.createElement("a");
    // Set the href attribute to the path of your Secret.jpg file
    link.href = secretImg;
    // Set the download attribute to specify the filename
    link.download = "Secret.jpg";
    // Append the link to the document body
    document.body.appendChild(link);
    // Simulate a click on the link to start the download
    link.click();
    // Clean up by removing the link from the document
    document.body.removeChild(link);
  };

  const goBack = () => {
    if (isVirtualClicked) {
      setIsVirtualClicked(false);
    } else {
      navigate("/rabbit-hole");
    }
  };

  const [isVirtualClicked, setIsVirtualClicked] = useState<boolean>(false);
  return (
    <ScreenshotsContainer>
      <DeeperContentContainer>
        {isVirtualClicked ? (
          <>
            <SecretText style={{ cursor: "none", textDecoration: "none" }}>
              Text
            </SecretText>
            <SecretText style={{ cursor: "none", textDecoration: "none" }}>
              „I want to know the virtual truth”
            </SecretText>
            <SecretText style={{ cursor: "none", textDecoration: "none" }}>
              to +44 7587 996107 to go
            </SecretText>
            <SecretText style={{ cursor: "none", textDecoration: "none" }}>
              deeper into the rabbit hole
            </SecretText>
          </>
        ) : (
          <>
            <SecretText onClick={handleDownload}>Digital Truth</SecretText>
            <SecretText onClick={() => setIsVirtualClicked(true)}>
              Virtual Truth
            </SecretText>
          </>
        )}
      </DeeperContentContainer>
      <BackContainer photos={true}>
        <BackIcon src={backIcon} onClick={goBack} />
      </BackContainer>
    </ScreenshotsContainer>
  );
};

export default Screenshots;
